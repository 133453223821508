.ExtraVideoPlayer {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background: black;
    transition: 0.5s ease;
    opacity: 0;
}

.ExtraVideoPlayer.open {
    opacity: 1;
}

.ExtraVideoPlayer video {
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

.ExtraVideoPlayer .closeBtn {
    position: fixed;
    top: 1em;
    left: 1em;
    font-size: 3em;
    background: none;
    border: none;
    color: white;
    text-shadow: 2px 2px 2px black;
    cursor:pointer;
    transition: 0.2s ease;
    opacity: 1;
}

.ExtraVideoPlayer .closeBtn.hidden {
    opacity: 0;
}