@font-face {
  font-family: 'Roboto';
  src: url('./fonts/RobotoCondensed-Regular.woff'),
    url('./fonts/RobotoCondensed-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid {
  display: grid;
}

.grid.c1fr1fr {
  grid-template-columns: 1fr 1fr;
}
.grid.c1fr1fr1fr {
  grid-template-columns: 1fr 1fr 1fr;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.noMouse {
  pointer-events: none;
}

.spinning {
  animation: spinning 0.25s linear infinite;
}

.bg-blue {
  background: #355e9c;
  color: white;
}
.bg-green {
  background: #359c35;
  color: white;
}
.bg-red {
  background: #9c3535;
  color: white;
}
.bg-yellow {
  background: #9c9535;
  color: white;
}


@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}