.VideoPlayer {
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background: black;
    backdrop-filter: blur(10px);
    display:flex;
    align-items: center;
    transition: 0.5s ease;
    opacity: 0;
    color: white;
}

.VideoPlayer.open {
    opacity: 1;
}

.VideoPlayer .closeButton {
    position: fixed;
    top: 1rem;
    left: 1rem;
    font-size: 3em;
    background: none;
    border: none;
    color: white;
    text-shadow: 2px 2px 2px black;
    cursor:pointer;
    opacity: 1;
    transition: 0.5s ease;
}

.VideoPlayer .controls {
    position: fixed;
    bottom: 0;
    transition: 0.5s ease;
    opacity: 1;
    left: 0;
    width: 100%;
    padding: 1em;
    background: linear-gradient(transparent, #00000070);
}

.VideoPlayer .controls .timeArea {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.VideoPlayer .controls .timeArea>* {
    margin: 0.5em;
}

.VideoPlayer .controls .progressBar {
    height: 0.5em;
    background: #ffffff70;
    box-shadow: 2px 2px 2px black;
    margin: 1em 0;
}

.VideoPlayer .controls .progressBar .fg {
    background: #ff0000;
    height: 100%;
    pointer-events: none;
}

.VideoPlayer .controls .buttons {
    display: grid;
    grid-template-columns: auto auto 1fr auto auto;
    align-items: center;
    text-shadow: 2px 2px 2px black;
    
}

.VideoPlayer .controls .buttons>* {
    padding: 0.5em;
}

.VideoPlayer .controls .buttons>button {
    font-size: 2em;
    background: none;
    border: none;
    color: white;
    text-shadow: 2px 2px 2px black;
    cursor: pointer;
}

.VideoPlayer .controls .playlist {
    position:absolute;
    top: -400px;
    right: 0;
    width: 400px;
    background: #404040;
    height: 400px;
    overflow: auto;
    border-radius: 4px;
    box-shadow: 2px 2px 2px black;
    transition: 0.2s ease;
    pointer-events: none;
    opacity: 0;
}

.VideoPlayer .controls .playlist::-webkit-scrollbar {
    width: 8px;
}

.VideoPlayer .controls .playlist::-webkit-scrollbar-thumb {
    background: #ffffff70;
}

.VideoPlayer .controls .playlistButton:hover .playlist {
    pointer-events: initial;
    opacity: 1;
}

.VideoPlayer .controls .playlist>button {
    display:block;
    width: 100%;
    padding: 1em;
    background: none;
    border: none;
    border-bottom: solid thin #00000040;
    color: inherit;
    text-align: left;
}

.VideoPlayer .controls .playlist>button:hover {
    background: #ffffff20;
}

.VideoPlayer .controls .playlist>button.active {
    background: #ffffff40;
}

.VideoPlayer .controls.hidden, .VideoPlayer .closeButton.hidden {
    opacity: 0;
    cursor:none;
}

.VideoPlayer video.hidden {
    cursor: none;
}