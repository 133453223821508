.UserStats {
    color: #e0e0e0;
}

.UserStats h1,
.UserStats h2,
.UserStats h3,
.UserStats h4,
.UserStats h5,
.UserStats h6 {
    color: white;
}

.UserStats .headers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0.5rem;
}

.UserStats .headers .header {
    background: none;
    color: darkgrey;
    font-family: monospace;
    font-size: 0.7rem;
    text-transform: uppercase;
    border: none;
    cursor:pointer;
    text-align: left;
}

.UserStats .pageTitle button {
    background: none;
    color: inherit;
    border: none;
    font-size: inherit;
    padding: 0 0.5rem;
}

.UserStats .userTable {
    background: #00000020;
    border: solid thin #00000040;
    border-radius: 0.5rem;
    overflow: hidden;
    font-size: 0.9rem;
}

.UserStats .userTable .user {
    padding: 0.5rem;
    transition: 0.2s ease;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: solid thin #00000020;
    align-items: center;
}

.UserStats .userTable .user:last-child {
    border-bottom: none;
}

.UserStats .userTable .user:hover {
    background: #ffffff20;
    cursor:pointer;
}