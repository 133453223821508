.Footer {
    background: #3c4447;
    color: white;
    padding: 1rem;
    font-size: 0.9rem;
}

.Footer .footerLogo {
    width: 258px;
    max-width: 100%;
}

.Footer .links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        padding: 0.5rem;
    /* margin: 1rem; */
}

.Footer .links div {
    padding: 0.5rem;
}

.Footer .links ul {
    list-style: none;
}

.Footer .links ul li a {
    text-decoration: none;
    color: inherit;
}
.Footer .links ul li a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .Footer .links {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 425px) {
    .Footer .links {
        grid-template-columns: 1fr;
    }
}