.Event {
    background: white;
    color: black;
    /* padding: 1rem; */
}

.Event .yellow {
    color: #EABC01;
}

.Event .info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.Event .info .infoCol {
    padding-right: 1rem;
}

.Event .info .infoCol p {
    /* font-weight: bold; */
    margin: 0.5rem 0 1rem 0;
}
.Event .info .infoCol .title {
    font-weight: bold;
    margin: 1rem 0 0 0;
}

.btn:active {
    position: relative;
    top: 2px;
}

.Event .notice {
    margin: 0 auto;
    max-width: 400px;
    background: black;
    color: white;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem 0 1rem 0;
}

.Event .contacts {
    background: #ffcd00;
    padding: 1rem;
}

.Event .contacts .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.Event .contacts .contact {
    text-align: center;
}

.Event .contacts .contact .title {
    font-weight: bold;
}

.Event .contacts .contact p {
    margin: 0.5rem 0;
}

.Event .catchup {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.Event .catchup h3 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.Event .catchup .catchupVideo {
    margin: 0 0.5rem;
    position:relative;
}

.Event .catchup .catchupVideo video {
    width: 100%;
}

.Event .catchup .catchupVideo .vidCard {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    font-size: 2rem;
    color: #ffcd00;
    background-size: cover;
    aspect-ratio: 16/9;
}


.Event .header {
    background: #424b4e;
    color: white;
    /* padding: 4px; */
    font-size: 0.75rem;
}

.Event .header.sticky {
    position:fixed;
    top:0;
    left:0;
    width: 100%;
}

.Event .header ul {
    list-style: none;
}

.Event .header ul li a {
    color:inherit;
}

.Event .header ul li {
    display: inline-block;
    padding: 4px;
}

.Event .header button {
    background: none;
    border: none;
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    cursor: pointer;
}

.Event .videoContainer {
    text-align: center;
    background: black;
    padding: 1em;
    color: white;
}

.Event .videoContainer .mainVidCard {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    cursor:pointer;
}

.Event .videoContainer .mainVidCard .content {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.Event .extraVids {
    
    background: #818286;
    padding: 1em;
    color: white;
}

.Event .extraVids .card {
    /* background: #ffffff40; */
    /* position: relative; */
    /* padding-top: 56.25%; */
    margin: 0 0.5em;
}

.Event .extraVids .card .vid {
    position: relative;
}

.Event .extraVids .card .vid .playbtn {
    position:absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: #ffcd00;
    pointer-events: none;
}

.Event .extraVids .card video {
    width: 100%;
    cursor:pointer;
}

.Event .extraVids .card p {
    margin-bottom: 1em;
}

.Event .extraGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Event .videoContainer .vidgrid {
    /* display: grid; */
    background: #ffffff40;
    text-align: left;
    padding: 1em;
    margin: 1em;
    border-radius: 0.5em;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.vidgrid .mvidrow {
    padding: 1em;
}

.Event .extraVids .topRow {
    display:grid;
    grid-template-columns: 1fr 1fr;
    
}

.mapGrid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

.mapGrid img {
    max-width: 100%;
}

@media only screen and (max-width: 768px) {

    .mapGrid {
        grid-template-columns: 1fr;
    }

    .Event .extraGrid {
        grid-template-columns: 1fr 1fr;
    }

    .Event .videoContainer .vidgrid {
        grid-template-columns: 1fr;
    }

    .Event .contacts .container {
        grid-template-columns: 1fr 1fr;
    }

    .Event .info {
        grid-template-columns: 1fr 1fr;
    }
    
    .Event .catchup {
        grid-template-columns: 1fr 1fr;
    }

    .Event .catchup .catchupCol.grid {
        grid-template-columns: 1fr;
    }

}
@media only screen and (max-width: 425px) {

    .Event .extraVids .topRow {
        grid-template-columns: 1fr;
    }

    .Event .extraGrid {
        grid-template-columns: 1fr;
    }

    

    .Event .contacts .container {
        grid-template-columns: 1fr;
    }

    .Event .info {
        grid-template-columns: 1fr;
    }

    .Event .contacts .contact:nth-child(even) {
        text-align: right;
    }

    .Event .catchup {
        grid-template-columns: 1fr;
    }

    .Event .catchup .catchupCol.grid {
        grid-template-columns: 1fr;
    }

}