.LoginPage {
    background: #ffcd00;
    /* color: #f0f0f0; */
    padding: 1rem;
}

.LoginPage .box {
    background: #3c4447;
    color: #f0f0f0;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 0.5rem;
    transition: 0.5s ease;
    overflow: hidden;
}

.LoginPage .box h1,
.LoginPage .box h2,
.LoginPage .box h3,
.LoginPage .box h4,
.LoginPage .box h5,
.LoginPage .box h6 {
    color: white;
    /* padding: 1rem 0; */
}

.LoginPage .box .slides {
    background: #ffffff20;
    width: 300%;
    left: 0;
    position:relative;
    transition: 0.5s ease;
}

.LoginPage .box .slides .slide {
    padding: 1rem;
    /* border: solid 2px white; */
    width: 33.33%;
    vertical-align: top;
    display: inline-block;
}

.LoginPage .box .buttons {
    background: #00000040;
    padding: 1rem;
    text-align: right;
}

.LoginPage .box button {
    border: solid thin #00000040;
    padding: 0.5rem;
    /* font-size: inherit; */
    border-radius: 0.5rem;
    color:inherit;
    transition: 0.2s ease;
    cursor:pointer;
}

.LoginPage .box button.nobg {
    background: none;
    border: none;
}

.LoginPage .box button.nobg:hover {
    text-decoration: underline;
}

.LoginPage .box button.nobg:disabled {
    text-decoration: none;
}

.LoginPage .box .buttons button:active {
    position: relative;
    top:2px;
}

.LoginPage .box button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.LoginPage .box .inputGroup {
    margin: 1rem 0;
}

.LoginPage .box .inputGroup .title {
    text-transform: uppercase;
    font-family: monospace;
    margin: 4px;
}

.LoginPage .box .inputGroup input {
    width: 100%;
    padding: 0.5rem;
    background: #00000020;
    border: solid thin #00000040;
    border-radius: 0.5rem;
    outline: none;
    transition: 0.2s ease;
    color: inherit;
    font-size: inherit;
}

.LoginPage .box .inputGroup input:disabled {
    opacity: 0.5;
}

.LoginPage .box .inputGroup input:hover {
    padding-left: 0.7rem;
    border: solid thin #ffcd0070;
}
.LoginPage .box .inputGroup input:focus {
    padding-left: 1rem;
    border: solid thin #ffcd00;
}

.LoginPage .box .message {
    padding: 1rem;
}