.ViewUser {
    background: #00000070;
    position:fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(10px);
    transition: 0.5s ease;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ViewUser.open {
    opacity: 1;
}

.ViewUser .box {
    background: #212527;
    width: 100%;
    max-width: 800px;
    position: relative;
    top: 100px;
    transition: 0.5s ease;
    padding: 1rem;
    box-shadow: 0.5rem 0.5rem 0.5rem #00000070;
}

.ViewUser.open .box {
    top: 0;
}

.ViewUser .vidGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.ViewUser .vidGrid .vidCell {
    border: solid thin #ffffff20;
    padding: 0.5rem;
    background: #ff000020;
}

.ViewUser .vidGrid .vidCell.watched {
    background: #00ff0020;
}
.ViewUser .vidGrid .vidCell.watching {
    background: #ffff0020;
}

.ViewUser .buttons button {
    padding: 0.5rem;
    color: inherit;
    background: #00000020;
    border: solid thin #00000040;
    border-radius: 0.5rem;
    margin: 0.5rem 0;
}