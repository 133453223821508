.AdminPanel {
    background: #212527;
    width: 100%;
    height: 100vh;
    position: fixed;
    top:0;
    left:0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    color: #e0e0e0;
    transition: 0.5s ease;
    opacity: 0;
}

.AdminPanel.open {
    opacity: 1;
}

.AdminPanel h1,
.AdminPanel h2,
.AdminPanel h3,
.AdminPanel h4,
.AdminPanel h5,
.AdminPanel h6 {
    color: white;
}

.AdminPanel .body {
    background: #3c4447;
    padding: 2rem;
    overflow: auto;
}

.AdminPanel .categories {
    padding: 2rem 0;
}

.AdminPanel .categories .box {
    float: right;
    width: 100%;
    max-width: 200px;
}

.AdminPanel .categories ul {
    list-style: none;
}

.AdminPanel .categories ul li {
    margin: 4px 0;
    padding: 0.5rem;
    border-radius: 0.5rem 0 0 0.5rem;
    cursor: default;
    transition: 0.2s ease;
}

.AdminPanel .categories ul li:hover {
    background: #3c444770;
}
.AdminPanel .categories ul li.active {
    background: #3c4447;
}

.AdminPanel .body .inner {
    width: 100%;
    max-width: 1200px;
    position: relative;
}

.AdminPanel .body .inner .closeBtn {
    color:inherit;
    font-size: 2rem;
    background: none;
    border: none;
    position:absolute;
    top: 0;
    right: 0;
}

.AdminPanel .body .inner .closeBtn:hover i:first-child {
    display: none;
}
.AdminPanel .body .inner .closeBtn:hover i:last-child {
    display: initial;
}
.AdminPanel .body .inner .closeBtn i:last-child {
    display: none;
}

.AdminPanel .body .analytics .quickStats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
}

.AdminPanel .body .analytics .quickStats .stat {
    border-right: solid thin #00000020;
    padding: 1rem;
}

.AdminPanel .body .analytics .quickStats .stat:last-child {
    border-right: none;
}

.AdminPanel .body .analytics .quickStats .stat p {
    font-size: 2rem;
}

.AdminPanel .categories p.title, .AdminPanel .body p.title {
    text-transform: uppercase;
    font-family: monospace;
    color: rgb(167, 167, 167);
    font-size: 0.7rem;
    margin: 4px;
}

.AdminPanel .categories p.title, .AdminPanel .body .field {
    margin: 1rem;
}

.AdminPanel .body .field input, .AdminPanel .body .field select, .AdminPanel .body button.submitBtn {
    width: 100%;
    padding: 0.5rem;
    background: #00000020;
    border: solid thin #00000040;
    color: inherit;
    border-radius: 0.5rem;
    transition: 0.2s ease;
    outline: none;
}

.AdminPanel .body .field select {
    background: #343b3e;
}

.AdminPanel .body .field input:hover {
    padding-left: 0.6rem;
    border: solid thin #ffcd0070;
}
.AdminPanel .body .field input:focus {
    padding-left: 1rem;
    border: solid thin #ffcd00;
}

.AdminPanel .body .field input:disabled, .AdminPanel .body .field select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.AdminPanel .body button:active {
    position: relative;
    top:2px;
}

.AdminPanel .body button.submitBtn {
    background: #309c4c;
    transition: 0.2s ease;
}

.AdminPanel .body button.submitBtn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.AdminPanel .body .error {
    background: #a52d2d;
    padding: 1rem;
    margin: 0.5rem 0;
}

.AdminPanel .body .field .inputGroup {
    display: grid;
    grid-template-columns: auto 1fr;
    /* grid-template-columns: 1fr auto; */
    background: #00000020;
    border: solid thin #00000040;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: 0.2s ease;
}

.AdminPanel .body .field .inputGroup input {
    
}

.AdminPanel .body .field .inputGroup input:hover {
    border: solid thin #ffcd0070;
}

.AdminPanel .body .field .inputGroup input:focus {
    border: solid thin #ffcd00;
}

.AdminPanel .body .field .inputGroup button {
    color: inherit;
    background: #3292c9;
    border: none;
    padding: 0.5rem;
}

.AdminPanel .body .field .inputGroup input {
    background: none;
    border-radius: 0 0.5rem 0.5rem 0;
    transition: 0.2s ease;
    border: solid thin transparent;
}

.AdminPanel .body .searchTable {
    background: #00000020;
    border: solid thin #00000040;
    border-radius: 0.5rem;
    overflow: hidden;
    font-size: 0.8rem;
}

.AdminPanel .body .searchTable .grid {
    border-bottom: solid thin #00000040;
    align-items: center;
}
.AdminPanel .body .searchTable .grid:last-child {
    border-bottom: none;
}
.AdminPanel .body .searchTable .grid:hover {
    background: #ffffff20;
}

.AdminPanel .body .searchTable .user {
    cursor: pointer;
}

.AdminPanel .body .searchTable .searchUser {
    padding: 0.5rem;
    
}

.AdminPanel .body .userStats {}


@media only screen and (max-width: 768px) {

    .AdminPanel .body .analytics .quickStats {
        grid-template-columns: 1fr;
        
    }

    .AdminPanel .body .analytics .quickStats .stat {
        border-right: none;
    }

}