.UserEditPanel {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    overflow:auto;
    display:flex;
    align-items: center;
    justify-content: center;
    background: #000000a0;
    backdrop-filter: blur(10px);
    transition: 0.5s ease;
    opacity: 0;
}

.UserEditPanel.open {
    opacity: 1;
}

.UserEditPanel .box {
    width: 90%;
    max-width: 800px;
    background: #212527;
    position: relative;
    top: 100px;
    transition: 0.5s ease;
    padding: 1rem;
}

.UserEditPanel.open .box {
    top: 0;
}

.UserEditPanel .field .grid {
    grid-template-columns: auto 1fr;
}

.UserEditPanel .field .reroll {
    padding: 0.5em;
    font-size: inherit;
    background: none;
    color:inherit;
    border: none;
}

.UserEditPanel .field .reroll i {
    pointer-events: none;
}

.UserEditPanel .field .reroll:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}