.AnalyticsReport {
    background: white;
    padding: 1em;
}

.AnalyticsReport .users {

}

.AnalyticsReport .users .user {
    border: solid thin #00000040;
    padding: 0.5em;
    margin: 0.5em 0;
    page-break-after:auto;
}

.AnalyticsReport .users .user:nth-child(3n) {
    page-break-after:always;
}

.AnalyticsReport .users .user .progressGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.AnalyticsReport .users .user .vidSquare {
    display: inline-block;
    margin: 0.25em;
}

.AnalyticsReport .users .user .vidSquare .inner {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid thin black;
    border-radius: 0.5em;


}